// External imports
import { SnackbarProvider } from 'notistack'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Internal imports
import { useContext } from 'react'
import './App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import RequireAuth from './components/router/RequireAuth'
import LoginAuth from './components/router/LoginAuth'
import MultiFactorAuthWizzard from './components/organisms/wizzards/MultiFactorAuthWizzard'
import { LayoutThemeContext } from './context/LayoutThemeContext'
import { TLayoutThemeContext } from './context/LayoutThemeContext/types'
import useWindowWidth from './hooks/useWindowWidth' // Import the custom hook

const App = () => {
  const { appMuiTheme } = useContext(LayoutThemeContext) as TLayoutThemeContext
  const windowWidth = useWindowWidth() // Get the current window width

  return (
    <MuiThemeProvider theme={appMuiTheme}>
      <SnackbarProvider maxSnack={4} autoHideDuration={4000}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route element={<LoginAuth />}>
              <Route path="/login" element={<MultiFactorAuthWizzard />} />
            </Route>
            <Route path="/*" element={<RequireAuth />} />
          </Routes>
        </BrowserRouter>
        {windowWidth < 768 && (
          <p className="desktop-message">This view is best experienced on a desktop</p>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </SnackbarProvider>
    </MuiThemeProvider>
  )
}

export default App
