import { checkboxClasses, PaletteMode, ThemeOptions } from '@mui/material'

export const DARK_FRONT_COLORS = {
  primary: {
    main: '#1F2B37',
    dark: '#B1A265',
    light: '#F9E490',
    contrastText: '#424344',
    contrast: '#A6A6A6',
  },
  secondary: {
    main: '#25184A',
    contrastText: '#F5F5F5',
  },
  info: {
    main: '#0090E0',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#33B960',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#F99630',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#F44336',
    contrastText: '#FFFFFF',
  },
  boxColor: {
    main: '#121212',
  },
  previewContainer: {
    main: '#000',
  },
  dropdownContainer: {
    main: 'rgba(255, 255, 255, 0.23)',
    secondary: '#fff',
  },
  menuItems: {
    main: '#fff',
    secondary: '#fff',
  },
  modalBackground: {
    main: '#424344',
  },
  textBasic: {
    main: '#fff',
    contrast: '#fff',
  },
  text: {
    primary: '#ffffff',
    secondary: '#b3b3b3',
  },
  border: {
    main: '#515151',
  },
  tagsTheme: {
    main: '#E5EBF2',
    secondary: '#546473',
    contrastText: '#364451',
  },
  sidebarTheme: {
    main: '#000',
  },
  adminButton: {
    main: '#33B960',
    contrastText: '#fff',
  },
  userButton: {
    main: 'rgba(255, 255, 255, 0.16)',
    contrastText: '#fff',
  },
  tableTheme: {
    main: '#121212',
    contrastText: '#fff',
    headBackground: '#F3F3F3',
  },
  purpleTheme: {
    main: '#6E06FF',
    contrastText: '#fff',
    hover: '#8B38FF',
  },
  infoTextColor: {
    main: '#ff0000',
  },
}

export const LIGHT_FRONT_COLORS = {
  primary: {
    main: '#1F2B37',
    dark: '#B1A265',
    light: '#F9E490',
    contrastText: '#424344',
    contrast: '#424344',
  },
  secondary: {
    main: '#25184A',
    contrastText: '#F5F5F5',
    text: '#575757',
  },
  info: {
    main: '#0090E0',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#33B960',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#F99630',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#D32F2F',
    contrastText: '#FFFFFF',
  },
  boxColor: {
    main: '#F5F5F5',
  },
  previewContainer: {
    main: '#F5F5F5',
  },
  dropdownContainer: {
    main: 'rgba(0, 0, 0, 0.23)',
    secondary: '#fff',
  },
  menuItems: {
    main: '#fff',
    secondary: '#111',
  },
  modalBackground: {
    main: '#fff',
  },
  textBasic: {
    main: '#1F2B37',
    contrast: '#000',
  },
  text: {
    primary: '#080808',
    secondary: '#575757',
  },
  border: {
    main: '#dcdcdc',
  },
  tagsTheme: {
    main: 'rgba(0,0,0,0.08)',
    secondary: 'rgba(0,0,0,0.26)',
    contrastText: '#111',
  },
  sidebarTheme: {
    main: '#fff',
  },
  adminButton: {
    main: '#33B960',
    contrastText: '#fff',
  },
  userButton: {
    main: 'rgba(0, 0, 0, 0.08)',
    contrastText: '#111',
  },
  tableTheme: {
    main: '#fff',
    contrastText: '#111',
    headBackground: '#F3F3F3',
  },
  purpleTheme: {
    main: '#6E06FF',
    contrastText: '#fff',
    dark: '#8B38FF',
  },
  infoTextColor: {
    main: '#ff0000',
  },
}

/**
 * Material UI theme config for "Light Mode"
 */
export const LIGHT_THEME = {
  palette: {
    mode: 'light' as PaletteMode,
    background: {
      paper: '#F6F8FC',
      default: '#ffffff',
    },
    action: {
      active: '#a2a3a2',
    },
    ...LIGHT_FRONT_COLORS,
  },
}

/**
 * Material UI theme config for "Dark Mode"
 */
export const DARK_THEME = {
  palette: {
    mode: 'dark' as PaletteMode,
    background: {
      paper: '#F6F8FC',
      default: '#000000',
    },
    action: {
      active: '#757575',
    },
    ...DARK_FRONT_COLORS,
  },
}

export const typography: ThemeOptions = {
  typography: {
    fontFamily: `"PublicSansRegular", "Geist", "Arial", sans-serif`,
    h1: {
      fontSize: '96px',
      fontWeight: 300,
      lineHeight: '116.7%',
      letterSpacing: '-1.5px',
      textDecoration: 'none',
      marginBottom: 16,
    },
    h2: {
      fontSize: '60px',
      fontWeight: 300,
      lineHeight: '120%',
      letterSpacing: '-.5px',
      textDecoration: 'none',
    },
    h3: {
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: '116.7%',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 600,
      lineHeight: '2.4rem',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    h5: {
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    h6: {
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    body1: {
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    body2Light: {
      fontSize: '12px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    font13400: {
      color: '#364451',
      fontSize: '13px',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '20px',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    font13500: {
      fontSize: '13px',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    font13600: {
      fontSize: '13px',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '20px',
      letterSpacing: 0,
      textDecoration: 'none',
    },
    buttonTitle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: 0,
      textDecoration: 'none',
      textTransform: 'none',
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    listItemText: {
      color: '#546473',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      textTransform: 'none',
    },
    font14500: {
      color: '#475467',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      textTransform: 'none',
    },
    font17500: {
      color: '#364451',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      textTransform: 'none',
    },
    font17600: {
      color: '#1F2B37',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      textTransform: 'none',
    },
    font30600: {
      color: '#1F2B37',
      fontSize: '3rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      letterSpacing: '-0.6px',
    },
  },
}

export const components: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // minWidth: '14rem',
          minWidth: '1rem',
          justifyContent: 'start',
          fontSize: '1.5rem',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '.8rem',
            padding: '1rem 1.8rem',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            textTransform: 'capitalize',
            fontSize: '1.7rem',
            fontWeight: 600,
            lineHeight: '2.4rem',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '80rem',
          },
        },
        {
          props: { color: 'inherit' },
          style: {
            height: '100%',
          },
        },
        {
          props: { variant: 'fileActions' },
          style: {
            backgroundColor: '#F7F8FF',
            color: '#6C6C6C',
            borderRadius: '.5rem',
            minWidth: 'unset',
            width: 'fit-content',
            padding: '.8rem 1.4rem',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '.5rem',
          border: '1px solid #D0D9E3',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          '.MuiButtonGroup-grouped': {
            padding: '.8rem 1.6rem',
            textTransform: 'none !important',
            cursor: 'pointer',
          },
          '.MuiButtonGroup-grouped:not(:first-of-type)': {
            borderRadius: '.5rem',
            borderColor: 'transparent',
          },
          '.MuiButtonGroup-grouped:not(:last-of-type)': {
            border: 'none',
            borderRadius: '.5rem',
          },
          '.MuiButtonGroup-grouped:not(:first-of-type).MuiButtonGroup-grouped:not(:last-of-type)': {
            borderRadius: 0,
            borderRight: ' 1px solid var(--DSM_Gray-03, #D0D9E3)',
            borderLeft: ' 1px solid var(--DSM_Gray-03, #D0D9E3)',
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#A1AEBD',
          transform: 'scale(1.6)',
          marginLeft: '1.5rem',
          marginRight: '.8rem',
          padding: 0,
          [`&.${checkboxClasses.checked}`]: {
            color: '#6E06FF',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 1.2rem',
          background: '#221646',
          height: '9rem',
          color: '#fff',
          boxShadow:
            'box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '.8rem 2rem .8rem 1.2rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textAlign: 'start',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset !important',
          marginRight: '.8rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '3rem',
          height: '1.8rem',
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            top: '.2rem',
            left: '.2rem',
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#6E06FF',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
          },

          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: '1.4rem',
            height: '1.4rem',
          },
          '& .MuiSwitch-track': {
            borderRadius: '1.4rem',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '1.6rem',
          height: '1.6rem',
          padding: 0,
        },
      },
    },
  },
}
