/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Box, Button, FormControl, FormHelperText, Grid, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useMsal } from '@azure/msal-react'
import AppleSignin from 'react-apple-signin-auth'
import { loginRequest } from '../../../config/authConfig'
import './LoginPage.scss'
import DSMLogo from '../../../assets/images/DSMLogin.png'
import PasswordInput from '../../atoms/PasswordInput'
import { ILoginPage } from './types'
import TextInput from '../../atoms/TextInput'
import PoweredByBinarii from '../../atoms/PoweredByBinarii'
import useAuths from '../../../hooks/useAuths'
import config from '../../../config'

declare global {
  interface Window {
    google: any
  }
}
interface AppleSignInResponse {
  authorization: {
    code: string
    id_token: string
    state: string
  }
  user: {
    name: {
      firstName: string
      lastName: string
    }
    email: string
  }
}

const LoginPage: React.FC<ILoginPage> = ({ loginSubmitFunction, navigateToForgotPassword }) => {
  const [usernameError, setUsernameError] = useState<boolean | null>(null)
  const [username, setUsername] = useState<string>('')
  const [passwordError, setPasswordError] = useState<boolean | null>(null)
  const [password, setPassword] = useState<string>('')
  const [loginError, setLoginError] = useState<string>('')
  const { instance } = useMsal()
  const baseURL = config.REACT_APP_BASE_URL
  const { handleAuth } = useAuths(`${baseURL}/sso-login`)

  // Disable right-click and dev tool shortcuts
  useEffect(() => {
    const disableRightClick = (e: MouseEvent) => {
      e.preventDefault()
    }

    const disableDevTools = (e: KeyboardEvent) => {
      // Disable developer tool shortcuts for all browsers (Chrome, Firefox, Safari, Edge)
      if (
        e.key === 'F12' ||
        e.code === 'F12' || // F12 (DevTools)
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.code === 'KeyI')) || // Ctrl + Shift + I (Chrome, Edge DevTools)
        (e.ctrlKey && e.shiftKey && (e.key === 'J' || e.code === 'KeyJ')) || // Ctrl + Shift + J (Chrome, Edge Console)
        (e.ctrlKey && e.shiftKey && (e.key === 'C' || e.code === 'KeyC')) || // Ctrl + Shift + C (Element Inspector)
        (e.ctrlKey && e.shiftKey && (e.key === 'K' || e.code === 'KeyK')) || // Ctrl + Shift + K (Firefox Console)
        (e.ctrlKey && e.shiftKey && (e.key === 'E' || e.code === 'KeyE')) || // Ctrl + Shift + E (Firefox Network)
        (e.ctrlKey && (e.key === 'U' || e.code === 'KeyU')) || // Ctrl + U (View Source in most browsers)
        (e.metaKey && e.altKey && (e.key === 'I' || e.code === 'KeyI')) || // Cmd + Opt + I (macOS DevTools)
        (e.metaKey && e.altKey && (e.key === 'J' || e.code === 'KeyJ')) || // Cmd + Opt + J (macOS Console)
        (e.metaKey && e.altKey && (e.key === 'C' || e.code === 'KeyC')) || // Cmd + Opt + C (macOS Inspector)
        (e.metaKey && e.altKey && (e.key === 'U' || e.code === 'KeyU')) || // Cmd + Opt + U (macOS View Source)
        (e.metaKey && e.altKey && (e.key === 'E' || e.code === 'KeyE')) || // Cmd + Opt + E (Safari Network in macOS)
        (e.metaKey && e.altKey && (e.key === 'K' || e.code === 'KeyK')) // Cmd + Opt + K (macOS Console in Firefox)
      ) {
        e.preventDefault()
      }
    }

    document.addEventListener('contextmenu', disableRightClick)
    document.addEventListener('keydown', disableDevTools)

    return () => {
      document.removeEventListener('contextmenu', disableRightClick)
      document.removeEventListener('keydown', disableDevTools)
    }
  }, [])

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
        callback: (response: any) => handleAuth({ credential: response.credential }, 'google'),
        auto_select: false,
      })

      const googleButtonContainer = document.getElementById('loginDiv')
      if (googleButtonContainer) {
        window.google.accounts.id.renderButton(googleButtonContainer, {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: 'continue_with',
          shape: 'pill',
          logo_alignment: 'center',
          width: 370,
        })

        // Optionally center the button and adjust the container styling
        googleButtonContainer.style.display = 'flex'
        googleButtonContainer.style.justifyContent = 'center'
        googleButtonContainer.style.marginBottom = '8px'
      }
    }
  }, [handleAuth])

  const handleMicrosoftLogin = async () => {
    try {
      const response = await instance.loginPopup({
        ...loginRequest,
        prompt: 'select_account',
      })
      await handleAuth({ credential: response.accessToken }, 'microsoft')
    } catch (e) {
      let errorMessage = 'An unexpected error occurred'
      if (e instanceof Error) {
        errorMessage = e.message
      } else if (typeof e === 'string') {
        errorMessage = e
      }
      const regex = /^(ServerError: invalid_client: AADSTS650052:|BrowserAuthError: interaction_in_progress:)/;
      const match = errorMessage.match(regex);
      const displayMessage = match ? match[0] : errorMessage;

    toast.error(displayMessage);

    }
  }
  const handleAppleLogin = async (response: AppleSignInResponse) => {
    try {
      await handleAuth({ credential: response.authorization.id_token }, 'apple')
    } catch (e) {
      toast.error('Apple login failed' + e)
    }
  }

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const onLoginFormSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    setPasswordError(null)
    setLoginError('')
    setUsernameError(null)
    let hasErrors
    if (!username) {
      setUsernameError(true)
      hasErrors = true
    }
    if (!password) {
      setPasswordError(true)
      hasErrors = true
    }
    if (hasErrors) return

    await loginSubmitFunction(username, password)
  }

  // username and password are mandatory
  const isButtonDisabled = () => username.length === 0 || password.length === 0

  return (
    <Grid sx={{ height: '85vh', bgcolor: 'background.paper' }}>
      <Grid height="100%" container justifyContent="center" alignItems="center">
        <Grid
          item
          className="login-inner-container"
          sx={{ '&': { display: 'flex', flexDirection: 'column', alignItems: 'center' } }}
        >
          <Box className="login-box">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  width: '12.8rem',
                  height: '4.4rem',
                }}
              >
                <img src={DSMLogo} alt="dsm logo" />
              </Box>
            </Box>
            <form className="login-form" onSubmit={onLoginFormSubmit}>
              <FormControl>
                <TextInput
                  variant="outlined"
                  value={username}
                  onChange={onUsernameChange}
                  label="Username"
                  type="text"
                  id="username-input"
                  aria-describedby="username-helper-text"
                  fullWidth
                  autoComplete="username"
                  error={!!usernameError}
                  errorMessage="Username cannot be empty"
                />
              </FormControl>
              <FormControl>
                <PasswordInput
                  password={password}
                  onPasswordChange={onPasswordChange}
                  variant="outlined"
                  sx={{ mt: 2 }}
                  label="Password"
                  fullWidth
                  aria-describedby="password-helper-text"
                  autoComplete="password"
                  id="password-input"
                />
                {passwordError && (
                  <FormHelperText id="password-helper-text" error sx={{ fontSize: 12 }}>
                    Password cannot be empty
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                size="large"
                variant="contained"
                color="purpleTheme"
                sx={{
                  mt: '1.6rem',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '.5rem',
                }}
                type="submit"
                disabled={isButtonDisabled()}
              >
                Login
              </Button>
              {loginError && (
                <Typography color="error.main" textAlign="center" sx={{ mt: 1 }}>
                  {loginError}
                </Typography>
              )}
            </form>
            <Box
              id="microsoft-signin-button"
              sx={{ fontSize: '15px' }}
              onClick={handleMicrosoftLogin}
            >
              <button
                id="msal-signin"
                className="social-button bg-white text-gray-700 rounded-full flex items-center justify-center p-2 w-full mb-2 border border-gray-300 cursor-pointer hover:bg-gray-100"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                  alt="Microsoft Icon"
                  className="mr-2 w-9 h-9"
                />
                <span>Continue with Microsoft</span>
              </button>
            </Box>
            <Box sx={{ marginTop: '-20px' }}>
              <AppleSignin
                authOptions={{
                  clientId: process.env.REACT_APP_APPLE_CLIENT as string,
                  scope: 'name email',
                  redirectURI: `${window.__REACT_APP_BASE_URL__}`,
                  state: 'state',
                  nonce: 'nonce',
                  usePopup: true,
                }}
                onSuccess={handleAppleLogin}
                onError={(error: Error) => console.error(error)}
                render={(props: any) => (
                  <Box id="apple-signin-button" sx={{ fontSize: '15px' }}>
                    <button
                      {...props}
                      id="apple-signin"
                      className="social-button bg-white text-gray-700 rounded-full flex items-center justify-center p-2 w-full mb-2 border border-gray-300 cursor-pointer hover:bg-gray-100"
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                        alt="Apple Icon"
                        className="mr-2 w-9 h-9"
                      />
                      <span>Continue with Apple</span>
                    </button>
                  </Box>
                )}
                uiType="light"
              />
            </Box>
            <Box className="w-full mb-2" sx={{ marginTop: '-20px', width: '100% !important' }}>
              <Box className="w-full flex justify-center" id="loginDiv" />
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  navigateToForgotPassword()
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '1.5rem',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '2.4rem',
                    textTransform: 'capitalize',
                    color: '#546473',
                  }}
                >
                  Forgot password?
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ height: '15vh', bgcolor: 'background.paper' }}>
        <PoweredByBinarii />
      </Grid>
    </Grid>
  )
}

export default LoginPage
